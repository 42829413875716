import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class ToshiPlanSearchController extends Controller {
  declare locationTarget: HTMLSelectElement;
  declare dateTarget: HTMLSelectElement;

  static targets = ['location', 'date'];

  handleChange(event: Event) {
    event.preventDefault();

    Turbo.visit(`${this.data.get('urlValue')}/${this.locationTarget.value}/${this.dateTarget.value}`);
  }
}
