// controllers/invoicing_report_controller.ts
import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

import { opsUrl } from './shared/url';

export default class InvoicingReportController extends Controller {
  static targets = [
    'modal',
    'content',
    'deliveriesTab',
    'servicedTab',
    'returnsTab',
    'transfersTab',
    'deliveryData',
    'servicedData',
    'returnData',
    'transferData',
  ];

  connect() {
    this.addNavigationEventListeners();
  }

  async openModal(event: any) {
    this.modalTarget.classList.remove('hidden');
    const response = await this.getRow(event);

    this.contentTarget.innerHTML = response.data;
  }

  closeModal() {
    this.modalTarget.classList.add('hidden');
  }

  addNavigationEventListeners() {
    this.tabTargets().forEach((target) => {
      target.addEventListener('click', () => {
        // make the tab selected the active one
        this.tabTargets().forEach((t) => {
          t.classList.remove('active');
        });
        target.classList.add('active');

        const activeTab = target.dataset.tab;

        this.dataTargets().forEach((t) => {
          if (t.dataset.invoicingReportTarget === activeTab) {
            t.classList.remove('hidden');
          } else {
            t.classList.add('hidden');
          }
        });
      });
    });
  }

  headers() {
    const csrfMetaTag = document.querySelector("meta[name='csrf-token']");
    if (!csrfMetaTag) {
      throw new Error('CSRF meta tag not found');
    }

    const csrfToken = csrfMetaTag.getAttribute('content');
    if (!csrfToken) {
      throw new Error('CSRF token not found in meta tag');
    }

    return {
      'X-CSRF-Token': csrfToken,
    };
  }

  tabTargets(): HTMLElement[] {
    return [this.deliveriesTabTarget, this.servicedTabTarget, this.returnsTabTarget, this.transfersTabTarget];
  }

  dataTargets(): HTMLElement[] {
    return [this.deliveryDataTarget, this.servicedDataTarget, this.returnDataTarget, this.transferDataTarget];
  }

  async getRow(event: { target: { dataset: { url: any } } }) {
    const { url } = event.target.dataset;
    return axios.get(`${opsUrl()}/${url}`, { headers: this.headers() });
  }

  closeIfClickedOutside(event: { target: any }) {
    if (event.target === this.modalTarget) {
      this.closeModal();
    }
  }
}
