import { Controller } from '@hotwired/stimulus';

export default class ToggleController extends Controller {
  static classes = ['target', 'toggle'];
  static targets = ['button'];

  declare readonly targetClass: string;
  declare readonly toggleClass: string;
  declare readonly buttonTarget?: HTMLElement;
  declare readonly hasButtonTarget: boolean;

  toggle(event: Event): void {
    this.toggleElements.forEach((element) => {
      element.classList.toggle(this.toggleClass);
    });

    const expanded = !this.toggleElements[0].classList.contains(this.toggleClass);
    if (event.currentTarget instanceof HTMLElement) {
      event.currentTarget.setAttribute('aria-expanded', expanded.toString());
    }

    this.updateButton();
  }

  get toggleElements(): NodeListOf<Element> {
    return this.element.querySelectorAll(`.${this.targetClass}`);
  }

  updateButton() {
    if (this.hasButtonTarget) {
      const toggleElementsArray = Array.prototype.slice.call(this.toggleElements);
      const toggleClassRemoved = toggleElementsArray.every((element) => element.classList.contains(this.toggleClass));
      if (toggleClassRemoved) {
        this.buttonTarget?.classList.remove('translate', '-rotate-90');
        this.buttonTarget?.classList.add('-translate-y-2');
      } else {
        this.buttonTarget?.classList.remove('translate', '-rotate-90', '-translate-y-2');
        this.buttonTarget?.classList.add('translate', '-rotate-90');
      }
    }
  }
}
