import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';

export default class DatePickerController extends Controller {
  static targets = ['input', 'rangeInput'];

  declare inputTarget: HTMLInputElement;
  declare rangeInputTarget: HTMLInputElement;
  declare hasInputTarget: HTMLInputElement;
  declare hasRangeInputTarget: HTMLInputElement;

  initialize() {
    if (this.hasInputTarget) {
      flatpickr(this.inputTarget, {
        altInput: true,
        altFormat: 'j F',
        dateFormat: 'Y-m-d',
      });
    }
    if (this.hasRangeInputTarget) {
      flatpickr(this.rangeInputTarget, {
        mode: 'range',
        altInput: true,
        altFormat: 'j F',
        dateFormat: 'Y-m-d',
      });
    }
  }
}
