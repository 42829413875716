import { Controller } from '@hotwired/stimulus';

export default class ScanController extends Controller {
  static targets = ['input', 'transition', 'search', 'transitionTextInput', 'searchTextInput'];

  declare readonly inputTarget: HTMLInputElement;
  declare readonly hasInputTarget: boolean;
  declare readonly transitionTarget: HTMLDivElement;
  declare readonly searchTarget: HTMLDivElement;
  declare transitionTextInputTarget: HTMLInputElement;
  declare hasTransitionTextInputTarget: boolean;
  declare searchTextInputTarget: HTMLInputElement;
  declare hasSearchTextInputTarget: boolean;

  connect() {
    this.setTransitionMode();
  }

  redirectToTransitionMode() {
    if (!this.inputTarget.dataset.state) {
      const url = `${window.location.href}?state=${this.inputTarget.dataset.redirectState}&transition_mode=true`;
      window.location.href = url;
    }
  }

  toggleForm(target: HTMLElement, disabled: boolean) {
    const inputs = target.querySelectorAll('input');

    inputs.forEach((input) => {
      if (disabled) {
        input.setAttribute('disabled', '');
        input.classList.add('hidden');
      } else {
        input.removeAttribute('disabled');
        input.classList.remove('hidden');
      }
    });
  }

  setTransitionMode() {
    if (this.hasInputTarget) {
      if (this.inputTarget.checked && this.inputTarget.dataset.state) {
        this.toggleForm(this.transitionTarget, false);
        this.toggleForm(this.searchTarget, true);
        this.transitionTextInputTarget.focus();
      } else {
        this.toggleForm(this.transitionTarget, true);
        this.toggleForm(this.searchTarget, false);
        this.searchTextInputTarget.focus();
      }
    }
  }

  resetInputs() {
    if (this.hasTransitionTextInputTarget) {
      this.transitionTextInputTarget.value = '';
    }
    if (this.hasSearchTextInputTarget) {
      this.searchTextInputTarget.value = '';
    }
  }
}
