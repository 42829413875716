import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="back-to-top"
export default class BackToTop extends Controller {
  static targets = ['button'];

  declare buttonTarget: HTMLElement;

  displayButton() {
    this.buttonTarget.style.display =
      document.body.scrollTop > 100 || document.documentElement.scrollTop > 100 ? 'block' : 'none';
  }

  backToTop() {
    document.body.scrollTo({ top: 0, behavior: 'smooth' });
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
