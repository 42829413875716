import { Controller } from '@hotwired/stimulus';
import Sortable, { type SortableEvent } from 'sortablejs';

export default class DragAndDropController extends Controller {
  static targets: string[] = ['tasksContainer', 'taskOrderBanner', 'resetButton', 'submitButton'];

  declare tasksContainerTargets: HTMLElement[];
  declare taskOrderBannerTarget: HTMLElement;
  declare resetButtonTarget: HTMLButtonElement;
  declare submitButtonTarget: HTMLButtonElement;

  private newOrder: { [key: string]: string[] } = {};
  private originalOrder: { [key: string]: string[] } = {};
  private sortables: { [key: string]: Sortable } = {}; // Store Sortable instances

  connect() {
    this.setupSortable();
    this.taskOrderBannerTarget.style.display = 'none';
  }
  setupSortable() {
    this.tasksContainerTargets.forEach((container) => {
      const routeId = container.dataset.routeId as string;
      const vehicleId = container.dataset.vehicleId as string;

      const sortable = Sortable.create(container, {
        group: `tasks-${routeId}`, // Use a unique group identifier for each route
        animation: 0,
        handle: '.sortable-handle',
        filter: '.start-task, .end-task',
        scroll: true,
        swapThreshold: 0.7,
        scrollSensitivity: 15, // px, how near the mouse must be to an edge to start scrolling.
        scrollSpeed: 80, // px, speed of the scrolling
        bubbleScroll: true, // apply autoscroll to all parent elements, allowing for easier movement
        onStart: (evt: SortableEvent) => this.onDragStart(evt),
        onEnd: (evt: SortableEvent) => this.onDragEnd(evt, container, vehicleId),
      });

      if (routeId) {
        this.originalOrder[routeId] = sortable.toArray();
        this.sortables[routeId] = sortable;
      }
    });
  }

  private onDragStart(evt: SortableEvent) {
    this.taskOrderBannerTarget.style.display = 'none';
    evt.item.classList.add('chosen');
  }

  private onDragEnd(evt: SortableEvent, container: HTMLElement, vehicleId: string) {
    const taskElements = container.querySelectorAll('.task-card');
    const newOrder = [...taskElements].map((task) => task.dataset.id as string);

    // Find the hidden fields for this specific route
    const hiddenOrderField = document.querySelector<HTMLInputElement>(`#new_order_input_${vehicleId}`);

    if (hiddenOrderField) {
      hiddenOrderField.value = JSON.stringify(newOrder);
    }

    evt.item.classList.remove('chosen');
    this.taskOrderBannerTarget.style.display = 'flex';
  }

  resetOrder() {
    this.tasksContainerTargets.forEach((container) => {
      const routeId = container.dataset.routeId as string;
      if (routeId) {
        const originalTaskOrder = this.originalOrder[routeId];
        const sortable = this.sortables[routeId];

        sortable.sort(originalTaskOrder);
      }
    });

    // Hide the submit and reset buttons
    this.taskOrderBannerTarget.style.display = 'none';
  }
}
