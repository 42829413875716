import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class MultiSelectController extends Controller {
  declare select: TomSelect;

  connect() {
    this.initializeTomSelect();
  }

  disconnect() {
    this.destroyTomSelect();
  }

  initializeTomSelect() {
    if (!this.element) return;

    this.select = new TomSelect('.tom-select', {
      plugins: {
        checkbox_options: {
          checkedClassNames: ['ts-checked'],
          uncheckedClassNames: ['ts-unchecked'],
        },
        remove_button: {
          title: 'Remove this item',
        },
      },
    });
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}
