import { Controller } from '@hotwired/stimulus';

export default class ToggleAllController extends Controller {
  static classes = ['target', 'toggle'];
  private isExpanded: boolean = false;

  declare readonly targetClass: string;
  declare readonly toggleClass: string;

  resetAndToggleAll(event: Event): void {
    const timelineAssistantList = document.querySelector('#timeline-assistant-list');
    if (timelineAssistantList) {
      const toggleButtons = timelineAssistantList.querySelectorAll("[data-action='click->toggle#toggle']");

      toggleButtons.forEach((button) => {
        const expanded = (button as HTMLElement).getAttribute('aria-expanded') === 'true';
        if (this.isExpanded && expanded) {
          (button as HTMLElement).click(); // Collapse if currently expanded
        } else if (!this.isExpanded && !expanded) {
          (button as HTMLElement).click(); // Expand if currently collapsed
        }
      });

      if (event.currentTarget instanceof HTMLElement && event.currentTarget.textContent) {
        const button = event.currentTarget;
        const buttonText = event.currentTarget.textContent;
        button.textContent = buttonText.replace(this.isExpanded ? 'Hide' : 'Show', this.isExpanded ? 'Show' : 'Hide');
      }
      this.isExpanded = !this.isExpanded;
    }
  }
}
