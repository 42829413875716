import { Controller } from '@hotwired/stimulus';

export default class FormController extends Controller {
  static targets = ['click'];

  declare readonly clickTargets: HTMLElement[];

  click() {
    this.clickTargets.forEach((target) => target.click());
  }
}
