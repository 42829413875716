import Bugsnag from '@bugsnag/js';
import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

import { opsUrl } from './shared/url';

export default class LabelViewerController extends Controller {
  static targets = ['zpl', 'render'];
  declare zplTarget: HTMLTextAreaElement;
  declare renderTarget: HTMLElement;

  async submit(event: Event) {
    event.preventDefault();
    try {
      const csrfMetaTag = document.querySelector("meta[name='csrf-token']");
      if (!csrfMetaTag) {
        throw new Error('CSRF meta tag not found');
      }

      const csrfToken = csrfMetaTag.getAttribute('content');
      if (!csrfToken) {
        throw new Error('CSRF token not found in meta tag');
      }

      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      };

      const response = await axios.post(
        `${opsUrl()}/warehousing/label_viewer`,
        { zpl: this.zplTarget.value },
        { headers },
      );

      this.renderTarget.innerHTML = response.data.zpl_base_64;
    } catch (error: any) {
      Bugsnag.notify(error);
    }
  }
}
