import { Controller } from '@hotwired/stimulus';

export default class RescheduleController extends Controller {
  static targets = ['deliverySlotsContainer', 'submitButton'];

  private deliverySlotsByDate: { [key: string]: any[] } = {};

  connect() {
    this.deliverySlotsByDate = JSON.parse(this.data.get('delivery-slots') || '{}');
  }

  updateDeliverySlots(event: Event) {
    const selectedDate = (event.target as HTMLSelectElement).value;
    const deliverySlotsContainer = this.deliverySlotsContainerTarget as HTMLElement;
    const submitButton = this.submitButtonTarget as HTMLInputElement;

    if (selectedDate) {
      const slots = this.deliverySlotsByDate[selectedDate];
      if (slots && slots.length > 0) {
        let options = '<option value="">Select a delivery slot</option>';
        slots.forEach((slot) => {
          const startTime = slot.start_time.split('T')[1].split(':').slice(0, 2).join(':');
          const endTime = slot.end_time.split('T')[1].split(':').slice(0, 2).join(':');
          options += `
<option value="${slot.delivery_slot_id}">
  ${startTime} - ${endTime}
</option>`;
        });
        deliverySlotsContainer.innerHTML = `
<div>
  <label for="delivery_slot" class="block text-gray-700 font-medium mb-2">Delivery Slot</label>
  <select
    name="journey[delivery_slot_id]"
    id="delivery_slot"
    class="
      mt-1 block w-full p-2 bg-white border border-gray-300 rounded shadow-sm
      focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
    "
  >
    ${options}
  </select>
</div>
        `;
        submitButton.disabled = false;
      } else {
        deliverySlotsContainer.innerHTML = '<p>No available delivery slots for this date.</p>';
        submitButton.disabled = true;
      }
    } else {
      deliverySlotsContainer.innerHTML = '';
      submitButton.disabled = true;
    }
  }
}
