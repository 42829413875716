import { getCookie } from '@shared/helpers/cookies/getCookie';

export enum Environments {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export function appEnv(): Environments | null {
  return getCookie('app_environment');
}

export function opsUrl(): string {
  const env = appEnv();

  switch (env) {
    case Environments.Staging:
      return 'https://staging.admin.toshi.co/ops';
    case Environments.Production:
      return 'https://admin.toshi.co/ops';
    case Environments.Development:
      return 'https://admin.toshi.test/ops';
    default:
      return 'http://api.lvh.me:3000/v2';
  }
}
