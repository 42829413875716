import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import { debounce } from 'lodash';

import { mapKeysToCamel } from '@shared/helpers/mapKeys/mapKeysToCamel';

import { opsUrl } from './shared/url';

interface ResultRow {
  title: string;
  resourceUrl: string;
  tagLine: string;
}

interface HighlightedSnippet {
  [key: string]: string[];
}

export default class KBarController extends Controller {
  static targets = ['modal', 'form', 'term', 'results'];
  metaKeyPressed = false;

  declare modalTarget: HTMLDialogElement;
  declare formTarget: HTMLFormElement;
  declare termTarget: HTMLInputElement;
  declare resultsTarget: HTMLElement;

  initialize() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
    document.addEventListener('click', this.handleClickOutside);

    this.termTarget.addEventListener('input', debounce(this.handleTermChange, 500));
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('click', this.handleClickOutside);

    this.termTarget.removeEventListener('input', this.handleTermChange);
  }

  private handleClickOutside = (event: MouseEvent) => {
    if (event.target === this.modalTarget) {
      this.hideModal();
    }
  };

  private handleTermChange = () => {
    this.getSearchResults();
  };

  private handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Meta' || event.key === 'Command') {
      this.metaKeyPressed = true;
    } else if (event.key.toLowerCase() === 'k' && this.metaKeyPressed) {
      event.preventDefault();
      this.showModal();
    }
  };

  private handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Meta' || event.key === 'Command') {
      this.metaKeyPressed = false;
    }
  };

  async getSearchResults() {
    try {
      const response = await axios.get(`${opsUrl()}/kbar?term=${this.termTarget.value}`);
      const responseAsCamel = mapKeysToCamel(response.data);
      const { items, highlightedSnippets } = responseAsCamel;

      this.addResults(items, highlightedSnippets);
    } catch (error) {
      console.error(error);
    }
  }

  async submitForm(event: Event) {
    event.preventDefault();
    this.getSearchResults();
  }

  private addResults(rows: ResultRow[], highlightedSnippets: HighlightedSnippet[]) {
    this.clearResults();

    if (rows.length === 0) return;

    rows.forEach(({ resourceUrl, title, tagLine }, index) => {
      const snippet = highlightedSnippets[index];
      const anchor = this.createResultLink({ resourceUrl, title, tagLine }, snippet);
      this.resultsTarget.append(anchor);
    });
  }

  private clearResults() {
    while (this.resultsTarget.firstChild) {
      this.resultsTarget.firstChild.remove();
    }
  }

  private createResultLink(row: ResultRow, snippet: HighlightedSnippet) {
    const anchor = document.createElement('a');
    anchor.href = row.resourceUrl;
    const urlTitle = document.createElement('div');
    urlTitle.innerHTML = row.title;
    const span = document.createElement('span');
    span.textContent = row.tagLine;
    const belowTitle = document.createElement('div');
    belowTitle.className = 'below-title';
    anchor.append(urlTitle);
    belowTitle.append(span);

    Object.entries(snippet).forEach(([key, value]) => {
      const div = document.createElement('div');
      div.className = 'highlight';
      div.innerHTML = `Matched on ${key}: ${value[0]}`;
      belowTitle.append(div);
    });

    anchor.append(belowTitle);

    return anchor;
  }

  private hideModal() {
    this.modalTarget.close();
  }

  private showModal() {
    this.modalTarget.showModal();
  }
}
