import { Controller } from '@hotwired/stimulus';

export default class MapController extends Controller {
  static targets = ['map', 'timelineContainer'];

  declare readonly mapTarget: HTMLElement;
  declare readonly timelineContainerTarget: HTMLElement;

  toggleMapVisibility() {
    this.mapTarget.classList.toggle('hidden');
    this.updateTimelineContainerHeight();
  }

  updateTimelineContainerHeight() {
    if (this.mapTarget.classList.contains('hidden')) {
      this.timelineContainerTarget.classList.add('h-[calc(100vh-32px)]');
      this.timelineContainerTarget.classList.remove('h-[calc(100vh-540px)]');
    } else {
      this.timelineContainerTarget.classList.remove('h-[calc(100vh-32px)]');
      this.timelineContainerTarget.classList.add('h-[calc(100vh-540px)]');
    }
  }
}
