import { Controller } from '@hotwired/stimulus';

export default class ScrollController extends Controller {
  static targets = ['container'];

  declare readonly containerTarget: HTMLElement;
  declare readonly hasContainerTarget: boolean;

  connect() {
    this.scrollToCurrentTime();
  }

  scrollToCurrentTime() {
    const startDateTime = new Date(this.containerTarget.dataset.startDateTime!);
    const currentDateTime = new Date(this.containerTarget.dataset.currentDateTime!);

    if (
      startDateTime.getFullYear() === currentDateTime.getFullYear() &&
      startDateTime.getMonth() === currentDateTime.getMonth() &&
      startDateTime.getDate() === currentDateTime.getDate()
    ) {
      const scrollToHour = currentDateTime.getHours() - 1;
      const scrollPosition =
        Math.max(scrollToHour - 7, 0) * (document.querySelector('.w-hour') as HTMLElement).offsetWidth;
      this.containerTarget.scrollLeft = scrollPosition;
    }
  }
}
