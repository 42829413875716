import { Controller } from '@hotwired/stimulus';

export default class BackgroundController extends Controller {
  static targets = ['container', 'transitionForm'];
  static values = {
    keyIndex: Number,
    backgroundColors: Array,
  };

  declare readonly containerTarget: HTMLDivElement;
  declare readonly transitionFormTarget: HTMLDivElement;
  declare readonly hasTransitionFormTarget: boolean;
  declare keyIndexValue: number;
  declare backgroundColorsValue: string[];

  initialize() {
    this.keyIndexValue = 0;
    this.backgroundColorsValue = ['bg-red-500', 'bg-green-400', 'bg-white', 'bg-all'];
  }

  changeColor(colorClass: string) {
    this.backgroundColorsValue.forEach((color) => this.containerTarget.classList.remove(color));
    this.containerTarget.classList.add(colorClass);
  }

  changeFormColor() {
    this.transitionFormTarget.classList.replace('bg-red-100', 'bg-green-100');
  }

  handleTurboSubmitEnd(event: CustomEvent) {
    const responseCode = /20[01]/g;
    if (responseCode.test(event.detail.fetchResponse.response.status)) {
      this.success();
    } else {
      this.error();
    }
  }

  playSuccess() {
    const audio = new Audio('/audios/success.mp3');
    audio.play();
  }

  playError() {
    const audio = new Audio('/audios/error.mp3');
    audio.play();
  }

  error() {
    this.playError();
    this.changeColor('bg-red-500');
  }

  success() {
    this.playSuccess();
    if (this.hasTransitionFormTarget) {
      this.changeFormColor();
    } else {
      this.changeColor('bg-green-400');
    }
  }

  reset() {
    this.changeColor('bg-white');
  }

  handleKeydown(event: KeyboardEvent) {
    const pattern = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'b',
      'a',
    ];

    if (!pattern.includes(event.key) || event.key !== pattern[this.keyIndexValue]) {
      this.keyIndexValue = 0;
      return;
    }

    this.keyIndexValue += 1;

    if (pattern.length === this.keyIndexValue) {
      this.reset();
      this.containerTarget.classList.add('bg-all');
      this.keyIndexValue = 0;
    }
  }
}
